import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import { Container, makeStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'clsx';
import ListItemText from '@material-ui/core/ListItemText';
import Alert from '@material-ui/lab/Alert';
import SpecialtySelectField from 'components/forms/atoms/SpecialtySelectField';
import { useAuth } from 'AuthCtx';
import AppLoading from 'components/app/AppLoading';
import { useKit } from 'models/kits';
import { InvitationReason, useKitInvitation } from '../../../models/invitations';
import { useFormRules, useInputValidation } from '../../../hooks/form-rules';
import FormNames from '../../../components/forms/atoms/form-names';
import FormSection from '../../../components/forms/atoms/FormSection';
import SimpleInputField from '../../../components/forms/atoms/SimpleInputField';
import SimplePhoneField from '../../../components/forms/atoms/SimplePhoneField';
import FormSectionSubmit from '../../../components/forms/atoms/FormSectionSubmit';
import { useItems } from '../../../models/atoms/items-hook';
import CollectionKeys from '../../../models/atoms/collection-keys';
import { useItem } from '../../../models/atoms/item-hook';
import { useDatetimeFormat } from '../../../utils';
import DoctorSearchForm from '../../../components/forms/common-forms/DoctorSearchForm';

const useStyles = makeStyles((theme) => {
  return {
    list: {
      width: '100%',
    },
    listItem: {
      marginBottom: '1px',
    },
    listItemOdd: {
      backgroundColor: theme.palette.alternate.light,
    },
    listItemEven: {
      backgroundColor: 'white',
    },
    listItemOddActive: {
      backgroundColor: '#ffeee0',
    },
    listItemEvenActive: {
      backgroundColor: '#ffe5ce',
    },
  };
});

const InvitationItem = ({ invitation, i }) => {
  const classes = useStyles();

  const dateFormat = useDatetimeFormat();
  const { t } = useTranslation();

  return (
    <ListItem
      className={
        clsx(
          classes.listItem,
          i % 2 ? (
            invitation.read ? classes.listItemEven : classes.listItemEvenActive
          ) : (invitation.read ? classes.listItemOdd : classes.listItemOddActive),
        )
      }
    >
      <ListItemText
        primary={ `${ invitation.metadata.firstName } ${ invitation.metadata.lastName } ${ invitation.metadata.profession ? `(${ invitation.metadata.profession })` : '' }` }
        secondary={ `${ dateFormat(invitation.created_at) }` }
      />
      {'pending' === invitation.status
        && (
          <>
            {t('On hold')}
          </>
        )}
      {'reject' === invitation.status
      && (
        <>
          {
            t('Δεν αποδέχτηκε')
          }
        </>
      )}
    </ListItem>
  );
};

const HealthcareProfessionalDetailsForm = (
  {
    kitId,
    onSubmit,
    onError,
    notInvitations = true,
  },
) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { item: kit } = useItem(CollectionKeys.Kits, kitId);
  const { isKitPGX, isLoading: kitLoading } = useKit(kitId);

  const [ doctorData, setDoctorData ] = useState(null);

  const [ hasSelectedDoctor, setHasSelectedDoctor ] = useState(null);

  const { userAttributes } = useAuth();
  const { country } = userAttributes;

  const handleDoctorData = (data) => {
    setHasSelectedDoctor(null !== data);
    setDoctorData(data);
  };

  const {
    items: invitations,
    isLoading: invitationsLoading,
  } = useItems(
    CollectionKeys.Invitations,
    {
      queryOptions: {
        enabled: !!kit?.serial_number_id?.id,
      },
      filterQuery: {
        status_nin: [ 'accept' ],
        'registration_kit.serial_number_id.id': kit?.serial_number_id?.id,
      },
      defaultPagination: { limit: -1 },
    },
  );

  const {
    createInvitation,
  } = useKitInvitation({ kitId, reasonOfInvitation: InvitationReason.HEALTHCARE_PROFESSIONAL_SHARE_RESULTS });

  const [ isSubmitting, setSubmitting ] = useState(false);

  const {
    requiredFieldRule,
    phoneNumberRule,
    mobilePhoneNumberRule,
    emailRule,
  } = useFormRules();

  const [
    errorFirstName,
    helperTextFirstName,
    validateFirstName,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorLastName,
    helperTextLastName,
    validateLastName,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorProfession,
    helperTextProfession,
    validateProfession,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorEmail,
    helperTextEmail,
    validateEmail,
  ] = useInputValidation(
    [
      requiredFieldRule,
      emailRule,
    ],
  );

  const [
    errorMobileNumber,
    helperTextMobileNumber,
    validateMobileNumber,
  ] = useInputValidation(
    [
      mobilePhoneNumberRule,
    ],
  );

  const [
    errorBusinessNumber,
    helperTextBusinessNumber,
    validateBusinessNumber,
  ] = useInputValidation(
    [
      (v) => { return requiredFieldRule(v?.phoneNumber); },
      (v) => { return requiredFieldRule(v?.country); },
      phoneNumberRule,
    ],
  );

  const handleSubmit = (e) => {
    setSubmitting(true);
    e.preventDefault();

    if ((false === hasSelectedDoctor || null === hasSelectedDoctor) && notInvitations) {
      setSubmitting(false);
      setHasSelectedDoctor(false);
      return onError();
    }

    const formData = new FormData(e.target);
    const {
      [FormNames.firstName]: firstName,
      [FormNames.lastName]: lastName,
      [FormNames.profession]: profession,
      [FormNames.email]: email,
      [FormNames.mobileNumber]: mobileNumber,
      [FormNames.countryMobileNumber]: countryMobileNumber,
      [FormNames.businessNumber]: businessNumber,
      [FormNames.countryBusinessNumber]: countryBusinessNumber,
      [FormNames.brandName]: brandName,
    } = Object.fromEntries(formData);

    // greedy validation
    // don't validate all of them immediately

    if (![
      () => { return validateFirstName(firstName); },
      () => { return validateLastName(lastName); },
      () => { return validateProfession(profession); },
      () => { return validateEmail(email); },
      () => {
        return validateMobileNumber(
          {
            phoneNumber: mobileNumber,
            country: countryMobileNumber,
          },
        );
      },
      () => {
        return validateBusinessNumber(
          {
            phoneNumber: businessNumber,
            country: countryBusinessNumber,
          },
        );
      },
    ].every((f) => { return true === f(); })) {
      setSubmitting(false);
      return onError();
    }

    return Promise.resolve()
      .then(() => {
        return createInvitation({
          thirdPartyEmail: email,
          metadata: {
            firstName,
            lastName,
            specialty: profession,
            email,
            mobileNumber,
            businessNumber,
            brand_name: brandName,
          },
        });
      })
      .then((d) => {
        return onSubmit(d);
      })
      .catch(onError)
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (invitationsLoading || kitLoading) {
    return <AppLoading />;
  }

  return (
    <div>
      <Grid container item xs={ 12 }>
        <List className={ classes.list } style={ { padding: '24px' } }>
          {
            invitations?.map((invitation, i) => {
              return (
                <InvitationItem
                  key={ invitation.id }
                  invitation={ invitation }
                  i={ i }
                />
              );
            })
          }
        </List>
      </Grid>
      <Container>
        <Alert severity='info'>
          {t('healthcareProfessionalInvitationPrompt')}
        </Alert>
      </Container>

      <DoctorSearchForm
        notInvitations={ notInvitations }
        title={ t('doctorSearch') }
        func={ handleDoctorData }
        kitId={ kitId }
        country={ country }
        pgxError={ false === hasSelectedDoctor }
      />
      <form
        noValidate
        onSubmit={ handleSubmit }
        style={ {
          padding: '24px',
        } }
      >
        <FormSection label={ t('healthcareProfessionalDetails') }>
          <Grid container spacing={ 2 }>
            <Grid item xs={ 12 }>
              <Grid container spacing={ 2 }>
                <Grid item xs={ 12 } md={ 6 }>
                  <SimpleInputField
                    label={ t('firstName') }
                    lettersOnly
                    autoComplete='healthcare-professional-name'
                    defaultValue={ doctorData?.name }
                    validate={ validateFirstName }
                    name={ FormNames.firstName }
                    required
                    disabled={ isKitPGX && notInvitations }
                    error={ errorFirstName }
                    helperText={ helperTextFirstName }
                  />
                </Grid>
                <Grid item xs={ 12 } md={ 6 }>
                  <SimpleInputField
                    label={ t('lastName') }
                    autoComplete='healthcare-professional-family-name'
                    defaultValue={ doctorData?.family_name }
                    lettersOnly
                    name={ FormNames.lastName }
                    disabled={ isKitPGX && notInvitations }
                    required
                    validate={ validateLastName }
                    error={ errorLastName }
                    helperText={ helperTextLastName }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 }>
              <SimpleInputField
                label={ t('brandName') }
                autoComplete='brand-name'
                defaultValue={ doctorData?.brand_name }
                disabled={ isKitPGX && notInvitations }
                name={ FormNames.brandName }
              />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 }>
              <SpecialtySelectField
                label={ t('profession') }
                name={ FormNames.profession }
                disabled={ isKitPGX && notInvitations }
                isKitPGX={ isKitPGX }
                required
                autoComplete='healthcare-professional-profession'
                defaultValue={ doctorData?.specialty }
                type='text'
                error={ errorProfession }
                validate={ validateProfession }
                helperText={ helperTextProfession }
              />
            </Grid>
            <Grid item xs={ 12 }>
              <SimpleInputField
                label={ t('email') }
                name={ FormNames.email }
                disabled={ isKitPGX && notInvitations }
                required
                defaultValue={ doctorData?.email }
                autoComplete='healthcare-professional-email'
                type='email'
                validate={ validateEmail }
                error={ errorEmail }
                helperText={ helperTextEmail }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <SimplePhoneField
                required
                name={ FormNames.businessNumber }
                label={ t('businessNumber') }
                defaultCountryValue={ country ?? 'GR' }
                disabled={ isKitPGX && notInvitations }
                validate={ validateBusinessNumber }
                countrySelectName={ FormNames.countryBusinessNumber }
                defaultValue={ doctorData?.business_number || '' }
                error={ errorBusinessNumber }
                helperText={ helperTextBusinessNumber }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <SimplePhoneField
                name={ FormNames.mobileNumber }
                label={ t('mobileNumber') }
                defaultCountryValue={ country ?? 'GR' }
                countrySelectName={ FormNames.countryMobileNumber }
                validate={ validateMobileNumber }
                disabled={ isKitPGX && notInvitations }
                defaultValue={ doctorData?.phone_number || '' }
                error={ errorMobileNumber }
                helperText={ helperTextMobileNumber }
              />
            </Grid>
          </Grid>
        </FormSection>
        <FormSectionSubmit
          label={ t('save') }
          loading={ isSubmitting }
        />
      </form>
    </div>
  );
};

HealthcareProfessionalDetailsForm.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
};

HealthcareProfessionalDetailsForm.defaultProps = {
  onSubmit: () => { return null; },
  onError: () => { return null; },
};

export default HealthcareProfessionalDetailsForm;
