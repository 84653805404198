import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Divider, Select, MenuItem, ListItem, ListItemText, Container } from '@material-ui/core';
import useMediaQueries from 'hooks/media-query-hooks';
import { useItems } from 'models/atoms/items-hook';
import CollectionKeys from 'models/atoms/collection-keys';
import AppLoading from 'components/app/AppLoading';
import { useItem } from 'models/atoms/item-hook';
import { useKitInvitation } from 'models/invitations';
import { useFormRules, useInputValidation } from 'hooks/form-rules';
import FormNames from 'components/forms/atoms/form-names';
import List from '@material-ui/core/List';
import { useDatetimeFormat } from 'utils';
import clsx from 'clsx';
import Alert from '@material-ui/lab/Alert';
import DoctorSearchForm from 'components/forms/common-forms/DoctorSearchForm';
import FormSection from 'components/forms/atoms/FormSection';
import SimpleInputField from 'components/forms/atoms/SimpleInputField';
import SimplePhoneField from 'components/forms/atoms/SimplePhoneField';
import FormSectionSubmit from 'components/forms/atoms/FormSectionSubmit';
import PropTypes from 'prop-types';
import { useKit } from 'models/kits';
import SpecialtySelectField from 'components/forms/atoms/SpecialtySelectField';
import { useAuth } from 'AuthCtx';

const useStyles = makeStyles((theme) => {
  return {
    titleCta: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    list: {
      width: '100%',
    },
    listItem: {
      marginBottom: '1px',
    },
    listItemOdd: {
      backgroundColor: theme.palette.alternate.light,
    },
    listItemEven: {
      backgroundColor: 'white',
    },
    listItemOddActive: {
      backgroundColor: '#ffeee0',
    },
    listItemEvenActive: {
      backgroundColor: '#ffe5ce',
    },
  };
});

const InvitationItem = ({ invitation, i }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dateFormat = useDatetimeFormat();

  return (
    <ListItem
      className={
        clsx(
          classes.listItem,
          i % 2 ? (
            invitation.read ? classes.listItemEven : classes.listItemEvenActive
          ) : (invitation.read ? classes.listItemOdd : classes.listItemOddActive),
        )
      }
    >
      <ListItemText
        primary={ `${ invitation.metadata.firstName } ${ invitation.metadata.lastName } ${ invitation.metadata.profession ? `(${ invitation.metadata.profession })` : '' }` }
        secondary={ `${ dateFormat(invitation.created_at) }` }
      />
      {'pending' === invitation.status
        && (
          <>
            {t('On hold')}
          </>
        )}
      {'reject' === invitation.status
          && (
            <>
              {
                t('Δεν αποδέχτηκε')
              }
            </>
          )}
    </ListItem>
  );
};

const InviteADoctor = (props) => {
  const { title } = props;
  const classes = useStyles();
  const { isMd } = useMediaQueries();

  return (
    <Grid container spacing={ isMd ? 4 : 2 }>
      <Grid item xs={ 12 }>
        <div className={ classes.titleCta }>
          <Typography variant='h6' color='textPrimary'>
            {title}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={ 12 }>
        <Divider />
        <InvitationsForm />
      </Grid>
    </Grid>

  );
};

export default InviteADoctor;

const InvitationsForm = () => {
  const [ activeKitId, setActiveKitId ] = useState(null);
  const { t } = useTranslation();
  const { items: kits, itemsLoading } = useItems(CollectionKeys.Kits,
    { defaultPagination: { limit: -1 },
      filterQuery: {
        registrationStatus_nin: [ 'sample_pending' ],
        _where: {
          _or: [
            [{ 'thematic_package.thematic_category_id.name_ne': 'PGx' }],
            [{ 'thematic_package.thematic_category_id.name_eq': 'PGx' },
              { dynamic_report_available_eq: true,
                pdf_available: true },
            ],
          ],
        },
      } });

  if (itemsLoading) {
    return <AppLoading />;
  }
  return (
    <Grid container>
      <Grid item xs={ 12 }>
        <Typography variant='body2' color='textPrimary'>
          {t('Select a registered kit for which you wish to send an invitation to your doctor')}
        </Typography>
        <br />
        <Select
          style={ { minWidth: '200px' } }
          value={ activeKitId }
          onChange={ (e) => {
            return setActiveKitId(e.target.value);
          } }
          name='kit'
        >
          <MenuItem disabled value={ null }>
            {t('selectAKit')}
          </MenuItem>
          {
            kits.map((kit) => {
              return (
                <MenuItem key={ kit.id } value={ kit.id }>
                  {kit.serial_number_id.serial}
                </MenuItem>
              );
            })
          }
        </Select>
      </Grid>
      {
        !!activeKitId && (
          <>
            <SendInvitationForm kitId={ activeKitId } />
          </>
        )
      }
    </Grid>
  );
};

const SendInvitationForm = ({
  kitId,
  onSubmit,
  onError,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { item: kit } = useItem(CollectionKeys.Kits, kitId);

  const [ hasSelectedDoctor, setHasSelectedDoctor ] = useState(null);

  const [ doctorData, setDoctorData ] = useState(null);

  const { isKitPGX, isLoading: isKitLoading } = useKit(kitId);

  const { userAttributes } = useAuth();
  const { country } = userAttributes;

  const reason = 'healthcare-professional-share-results';

  /*
  if kit pgx + no consent ==> reason= 'PGX-DOCTOR-CONSENT'

  else kit reason = healthcare-proffesional-share-results'
  */
  const handleDoctorData = (data) => {
    setDoctorData(data);
    setHasSelectedDoctor(null !== data);
  };

  const {
    items: invitations,
    isLoading: invitationsLoading,
  } = useItems(
    CollectionKeys.Invitations,
    {
      queryOptions: {
        enabled: !!kit?.serial_number_id?.id,
      },
      filterQuery: {
        status_nin: [ 'accept' ],
        'registration_kit.serial_number_id.id': kit?.serial_number_id?.id,
      },
      defaultPagination: { limit: -1 },
    },
  );

  const {
    createInvitation,
  } = useKitInvitation({ kitId, reasonOfInvitation: reason });

  const [ isSubmitting, setSubmitting ] = useState(false);

  const {
    requiredFieldRule,
    phoneNumberRule,
    emailRule,
  } = useFormRules();

  const [
    errorFirstName,
    helperTextFirstName,
    validateFirstName,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorLastName,
    helperTextLastName,
    validateLastName,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorProfession,
    helperTextProfession,
    validateProfession,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorEmail,
    helperTextEmail,
    validateEmail,
  ] = useInputValidation(
    [
      requiredFieldRule,
      emailRule,
    ],
  );

  const [
    errorMobileNumber,
    helperTextMobileNumber,
    validateMobileNumber,
  ] = useInputValidation(
    [
      phoneNumberRule,
    ],
  );

  const [
    errorBusinessNumber,
    helperTextBusinessNumber,
    validateBusinessNumber,
  ] = useInputValidation(
    [
      (v) => { return requiredFieldRule(v?.phoneNumber); },
      (v) => { return requiredFieldRule(v?.country); },
      phoneNumberRule,
    ],
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    // eslint-disable-next-line no-constant-condition
    if ((false === hasSelectedDoctor || null === hasSelectedDoctor) && isKitPGX && false) {
      setSubmitting(false);
      setHasSelectedDoctor(false);
      return onError();
    }

    setSubmitting(true);
    const formData = new FormData(e.target);
    const {
      [FormNames.firstName]: firstName,
      [FormNames.lastName]: lastName,
      [FormNames.profession]: profession,
      [FormNames.email]: email,
      [FormNames.mobileNumber]: mobileNumber,
      [FormNames.countryMobileNumber]: countryMobileNumber,
      [FormNames.businessNumber]: businessNumber,
      [FormNames.countryBusinessNumber]: countryBusinessNumber,
      [FormNames.brandName]: brandName,
    } = Object.fromEntries(formData);

    // greedy validation
    // don't validate all of them immediately

    if (![
      () => { return validateFirstName(firstName); },
      () => { return validateLastName(lastName); },
      () => { return validateProfession(profession); },
      () => { return validateEmail(email); },
      () => {
        return validateMobileNumber(
          {
            phoneNumber: mobileNumber,
            country: countryMobileNumber,
          },
        );
      },
      () => {
        return validateBusinessNumber(
          {
            phoneNumber: businessNumber,
            country: countryBusinessNumber,
          },
        );
      },
    ].every((f) => { return true === f(); })) {
      setSubmitting(false);
      return onError();
    }

    return Promise.resolve()
      .then(() => {
        return createInvitation({
          thirdPartyEmail: email,
          metadata: {
            firstName,
            lastName,
            specialty: profession,
            email,
            mobileNumber,
            businessNumber,
            brand_name: brandName,
          },
          reason,
        });
      })
      .then((d) => {
        return onSubmit(d);
      })
      .catch(onError)
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (invitationsLoading || isKitLoading) {
    return (
      <AppLoading />
    );
  }

  return (
    <div>
      <Grid container item xs={ 12 }>
        <List className={ classes.list } style={ { padding: '24px' } }>
          {
            invitations?.map((invitation, i) => {
              return (
                <InvitationItem
                  key={ invitation.id }
                  invitation={ invitation }
                  i={ i }
                />
              );
            })
          }
        </List>
      </Grid>
      <Container>
        <Alert severity='info'>
          {t('healthcareProfessionalInvitationPrompt')}
        </Alert>
      </Container>
      <DoctorSearchForm
        pgxError={ false === hasSelectedDoctor && isKitPGX }
        title={ t('doctorSearch') }
        func={ handleDoctorData }
        kitId={ kitId }
        country={ country }
        notInvitations={ false }
      />
      <form
        noValidate
        onSubmit={ handleSubmit }
        style={ {
          padding: '24px',
        } }
      >
        <FormSection label={ t('healthcareProfessionalDetails') }>
          <Grid container spacing={ 2 }>
            <Grid item xs={ 12 }>
              <Grid container spacing={ 2 }>
                <Grid item xs={ 12 } md={ 6 }>
                  <SimpleInputField
                    label={ t('firstName') }
                    lettersOnly
                    autoComplete='healthcare-professional-name'
                    defaultValue={ doctorData?.name }
                    name={ FormNames.firstName }
                    validate={ validateFirstName }
                    required
                    error={ errorFirstName }
                    helperText={ helperTextFirstName }
                  />
                </Grid>
                <Grid item xs={ 12 } md={ 6 }>
                  <SimpleInputField
                    label={ t('lastName') }
                    autoComplete='healthcare-professional-family-name'
                    lettersOnly
                    defaultValue={ doctorData?.family_name }
                    name={ FormNames.lastName }
                    validate={ validateLastName }
                    required
                    error={ errorLastName }
                    helperText={ helperTextLastName }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={ 12 } sm={ 6 }>
              <SimpleInputField
                label={ t('brandName') }
                autoComplete='brand-name'
                defaultValue={ doctorData?.brand_name ?? '' }
                name={ FormNames.brandName }
              />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 }>
              <SpecialtySelectField
                label={ t('profession') }
                isKitPGX={ isKitPGX }
                name={ FormNames.profession }
                required
                autoComplete='healthcare-professional-profession'
                validate={ validateProfession }
                defaultValue={ doctorData?.specialty }
                type='text'
                error={ errorProfession }
                helperText={ helperTextProfession }
              />
            </Grid>
            <Grid item xs={ 12 }>
              <SimpleInputField
                label={ t('email') }
                name={ FormNames.email }
                required
                defaultValue={ doctorData?.email }
                autoComplete='healthcare-professional-email'
                type='email'
                error={ errorEmail }
                helperText={ helperTextEmail }
                validate={ validateEmail }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <SimplePhoneField
                name={ FormNames.businessNumber }
                required
                label={ t('businessNumber') }
                defaultCountryValue={ country ?? 'GR' }
                countrySelectName={ FormNames.countryBusinessNumber }
                defaultValue={ doctorData?.business_number || '' }
                error={ errorBusinessNumber }
                helperText={ helperTextBusinessNumber }
                validate={ validateBusinessNumber }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <SimplePhoneField
                name={ FormNames.mobileNumber }
                label={ t('mobileNumber') }
                validate={ validateMobileNumber }
                defaultCountryValue={ country ?? 'GR' }
                countrySelectName={ FormNames.countryMobileNumber }
                defaultValue={ doctorData?.phone_number || '' }
                error={ errorMobileNumber }
                helperText={ helperTextMobileNumber }
              />
            </Grid>
          </Grid>
        </FormSection>
        <FormSectionSubmit
          label={ t('save') }
          loading={ isSubmitting }
        />
      </form>
    </div>
  );
};

SendInvitationForm.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
};

SendInvitationForm.defaultProps = {
  onSubmit: () => { return null; },
  onError: () => { return null; },
};
