/* eslint-disable no-unreachable */
/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LimitedQuestionnaire from 'components/layout/LimitedQuestionnaire';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import useAPI from 'hooks/api-hooks';
import { useKit } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import SimpleInputField from '../atoms/SimpleInputField';
import SimpleRadio from '../atoms/SimpleRadio';
import SimpleDateField from '../atoms/SimpleDateField';
import SimplePhoneField from '../atoms/SimplePhoneField';
import { useFormRules, useInputValidation } from '../../../hooks/form-rules';
import FormSection from '../atoms/FormSection';
import FormSectionSubmit from '../atoms/FormSectionSubmit';
import FormNames from '../atoms/form-names';
import { useAuth } from '../../../AuthCtx';
import CountrySelectField, { countryNameToCode } from '../atoms/CountrySelectField';

const UserDetailsForm = (
  {
    onSubmit,
    onError,
    kitId,
    goToPrevPage,
  },
) => {
  const { t } = useTranslation();

  const [ pgxQuestionnaire, setPgxQuestionnaire ] = useState({});
  const [ pgxQuestionnaireErrorID, setPgxQuestionnaireErrorID ] = useState(-1);

  const [ enabledFields, setEnabledFields ] = useState(true);

  useEffect(() => {
    if (null == kitId) {
      setEnabledFields(false);
    }
  }, [ kitId ]);

  let isLoading = false;
  let questions = [];
  let isPGx = false;

  let hasReferral = false;

  if (null != kitId) {
    const props = useKit(kitId);
    isLoading = props.isLoading;
    questions = props.questions;
    isPGx = props.isKitPGX;
    hasReferral = null != props.data.metadata?.referenceNumber && '' !== props.data.metadata?.referenceNumber;
  }

  const { client } = useAPI();

  const { updateUserAttributes, userAttributes } = useAuth();

  const {
    firstName: initialFirstName,
    lastName: initialLastName,
    email: initialEmail,
    dateOfBirth: initialDateOfBirth,
    gender: initialGender,
    mobileNumber: initialMobileNumber,
    houseNumber: initialHouseNumber,
    streetAddress: initialStreetAddress,
    postalCode: initialPostalCode,
    country: initialCountry,
    city: initialCity,
  } = userAttributes;

  const [ isSubmitting, setSubmitting ] = useState(false);

  const {
    requiredFieldRule,
    phoneNumberRule,
    dateRule,
    genderRule,
    requiredPhoneRule,
    atMost10Rule,
  } = useFormRules();

  const [
    errorFirstName,
    helperTextFirstName,
    validateFirstName,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorLastName,
    helperTextLastName,
    validateLastName,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorGender,
    helperTextGender,
    validateGender,
  ] = useInputValidation(
    [
      requiredFieldRule,
      genderRule,
    ],
  );

  const [
    errorDateOfBirth,
    helperTextDateOfBirth,
    validateDateOfBirth,
  ] = useInputValidation(
    [
      dateRule,
    ],
  );

  const [
    errorStreetAddress,
    helperTextStreetAddress,
    validateStreetAddress,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorPostalCode,
    helperTextPostalCode,
    validatePostalCode,
  ] = useInputValidation(
    [
      requiredFieldRule,
      atMost10Rule,
    ],
  );

  const [
    errorCity,
    helperTextCity,
    validateCity,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorCountry,
    helperTextCountry,
    validateCountry,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorMobileNumber,
    helperTextMobileNumber,
    validateMobileNumber,
  ] = useInputValidation(
    [
      requiredPhoneRule,
    ],
  );

  const [
    errorHouseNumber,
    helperTextHouseNumber,
    validateHouseNumber,
  ] = useInputValidation(
    [
      phoneNumberRule,
    ],
  );

  const checkValidPGxQuestionnaire = () => {
    return true;
    if (0 === Object.entries(pgxQuestionnaire).length) {
      setPgxQuestionnaireErrorID(questions[0].id);
      return false;
    }

    return questions.every((que) => {
      if (null != que.referral) {
        if (!questions[Number(que.referral) - 1].referrence.options.includes(pgxQuestionnaire[que.referral])) {
          return true;
        }
      } else if (false === que.required || null === que.required) {
        return true;
      }

      if ('multiple_choice' === que.type) {
        const isValid = undefined !== pgxQuestionnaire[que.id] && 0 !== pgxQuestionnaire[que.id].length;

        if (!isValid) {
          setPgxQuestionnaireErrorID(que.id);
        }

        return isValid;
      }
      const isValid = undefined !== pgxQuestionnaire[que.id] && '' !== pgxQuestionnaire[que.id];

      if (!isValid) {
        setPgxQuestionnaireErrorID(que.id);
      }

      return isValid;
    });
  };

  const handleSubmit = (e) => {
    setSubmitting(true);
    e.preventDefault();
    const formData = new FormData(e.target);
    let {
      [FormNames.firstName]: firstName,
      [FormNames.lastName]: lastName,
      [FormNames.gender]: gender,
      [FormNames.dateOfBirth]: dateOfBirth,
      [FormNames.streetAddress]: streetAddress,
      [FormNames.postalCode]: postalCode,
      [FormNames.city]: city,
      [FormNames.country]: country,
      [FormNames.mobileNumber]: mobileNumber,
      [FormNames.countryMobileNumber]: countryMobileNumber,
      [FormNames.houseNumber]: houseNumber,
      [FormNames.countryHouseNumber]: countryHouseNumber,
    } = Object.fromEntries(formData);

    country = countryNameToCode(country);

    // greedy validation
    // don't validate all of them immediately

    if (![
      () => { return null !== kitId || validateFirstName(firstName); },
      () => { return null !== kitId || validateLastName(lastName); },
      () => { return null !== kitId || validateDateOfBirth(dateOfBirth); },
      () => { return null !== kitId || validateGender(gender); },
      () => {
        if (null == kitId || hasReferral) {
          return true;
        }
        return checkValidPGxQuestionnaire();
      },
      () => { return validateStreetAddress(streetAddress); },
      () => { return validatePostalCode(postalCode); },
      () => { return validateCity(city); },
      () => { return validateCountry(country); },
      () => {
        return validateMobileNumber(
          {
            phoneNumber: mobileNumber,
            country: countryMobileNumber,
          },
        );
      },
      () => {
        return validateHouseNumber(
          {
            phoneNumber: houseNumber,
            country: countryHouseNumber,
          },
        );
      },
    ].every((f) => {
      return true === f();
    })) {
      setSubmitting(false);
      return onError();
    }

    return updateUserAttributes({
      firstName,
      lastName,
      gender,
      streetAddress,
      postalCode,
      city,
      dateOfBirth,
      mobileNumber,
      country,
      houseNumber,
    })
      .then(() => {
        client.put('customers/me/details', { country });

        if (!hasReferral && null != kitId) {
          client.put('customers/me/details', { extra_info_pgx: pgxQuestionnaire });
        }
      })
      .then(() => {
        return onSubmit({
          firstName,
          lastName,
          gender,
          streetAddress,
          postalCode,
          city,
          dateOfBirth,
          mobileNumber,
          houseNumber,
        });
      })
      .catch(onError)
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (isLoading) {
    return (
      <LoaderSkeleton />
    );
  }

  return (
    <form
      noValidate
      onSubmit={ handleSubmit }
      style={ {
        padding: '24px',
      } }
    >
      <FormSection label={ null != kitId ? t('reg_kit.personal_data.adult.pgx.personal_data_title') : t('reg_kit.personal_data.adult.non_pgx.personal_data_title') }>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <Grid container spacing={ 2 }>
              <Grid item xs={ 12 } md={ 6 }>
                <SimpleInputField
                  label={ t('common.first_name') }
                  lettersOnly
                  validate={ validateFirstName }
                  autoComplete='given-name'
                  defaultValue={ initialFirstName }
                  name={ FormNames.firstName }
                  required
                  disabled={ null != kitId || !enabledFields }
                  error={ errorFirstName }
                  helperText={ helperTextFirstName }
                />
              </Grid>
              <Grid item xs={ 12 } md={ 6 }>
                <SimpleInputField
                  label={ t('common.last_name') }
                  lettersOnly
                  defaultValue={ initialLastName }
                  validate={ validateLastName }
                  autoComplete='family-name'
                  disabled={ null != kitId || !enabledFields }
                  name={ FormNames.lastName }
                  required
                  error={ errorLastName }
                  helperText={ helperTextLastName }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={ 12 } lg={ 6 }>
            <SimpleDateField
              label={ t('common.birthdate') }
              defaultValue={ initialDateOfBirth }
              validate={ validateDateOfBirth }
              name={ FormNames.dateOfBirth }
              disabled={ null != kitId || !enabledFields }
              required
              error={ errorDateOfBirth }
              helperText={ helperTextDateOfBirth }
            />
          </Grid>
          <Grid item xs={ 12 } lg={ 6 }>
            <SimpleRadio
              label={ t('common.gender') }
              secondaryLabel={ `${ t('common.gender_explanation') }` }
              disabled={ null != kitId || !enabledFields }
              defaultValue={ initialGender }
              name={ FormNames.gender }
              required
              options={ [
                {
                  value: 'male',
                  label: t('common.male'),
                },
                {
                  value: 'female',
                  label: t('common.female'),
                },
              ] }
              error={ errorGender }
              helperText={ helperTextGender }
            />
          </Grid>

        </Grid>

        {
          null != kitId && !hasReferral && !isPGx && (
            <Grid item xs={ 12 }>
              <LimitedQuestionnaire
                kitId={ kitId }
                wellnessQuestionnaire={ pgxQuestionnaire }
                setWellnessQuestionnaire={ setPgxQuestionnaire }
                wellnessQuestionnaireErrorID={ pgxQuestionnaireErrorID }
                setWellnessQuestionnaireErrorID={ setPgxQuestionnaireErrorID }
              />
            </Grid>
          )
        }

      </FormSection>
      <FormSection label={ t('common.postal_address') }>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <Grid container spacing={ 1 }>
              <Grid item xs={ 8 }>
                <SimpleInputField
                  label={ t('common.street_and_number') }
                  autoComplete='street-address'
                  defaultValue={ initialStreetAddress }
                  name={ FormNames.streetAddress }
                  required
                  disabled={ !enabledFields }
                  validate={ validateStreetAddress }
                  error={ errorStreetAddress }
                  helperText={ helperTextStreetAddress }
                />
              </Grid>

              <Grid item xs={ 4 }>
                <SimpleInputField
                  label={ t('common.postal_code') }
                  validate={ validatePostalCode }
                  defaultValue={ initialPostalCode }
                  autoComplete='postal-code'
                  disabled={ !enabledFields }
                  name={ FormNames.postalCode }
                  required
                  error={ errorPostalCode }
                  helperText={ helperTextPostalCode }
                />
              </Grid>
              <Grid item container spacing={ 2 }>
                <Grid item xs={ 12 } sm={ 8 }>
                  <SimpleInputField
                    label={ t('common.city') }
                    validate={ validateCity }
                    defaultValue={ initialCity }
                    name={ FormNames.city }
                    required
                    error={ errorCity }
                    disabled={ !enabledFields }
                    helperText={ helperTextCity }
                  />

                </Grid>

                <Grid item xs={ 12 } sm={ 4 }>
                  <CountrySelectField
                    label={ t('common.country') }
                    defaultValue={ initialCountry }
                    name={ FormNames.country }
                    readOnly={ !enabledFields }
                    required
                    error={ errorCountry }
                    helperText={ helperTextCountry }
                    validate={ validateCountry }
                  />
                </Grid>

              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </FormSection>
      <FormSection label={ t('common.communication_info') }>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <SimpleInputField
              name={ FormNames.email }
              label={ t('common.email') }
              required
              defaultValue={ initialEmail }
              readOnly
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <SimplePhoneField
              name={ FormNames.mobileNumber }
              label={ t('common.mobile_number') }
              defaultValue={ initialMobileNumber }
              autoComplete='tel-national'
              defaultCountryValue={ initialCountry ?? 'GR' }
              readOnly={ !enabledFields }
              countrySelectName={ FormNames.countryMobileNumber }
              validate={ validateMobileNumber }
              required
              error={ errorMobileNumber }
              helperText={ helperTextMobileNumber }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <SimplePhoneField
              name={ FormNames.houseNumber }
              defaultValue={ initialHouseNumber }
              autoComplete='tel-national'
              readOnly={ !enabledFields }
              label={ t('common.home_number') }
              defaultCountryValue={ initialCountry ?? 'GR' }
              countrySelectName={ FormNames.countryHouseNumber }
              error={ errorHouseNumber }
              helperText={ helperTextHouseNumber }
            />
          </Grid>
        </Grid>
      </FormSection>

      {
        enabledFields ? (
          <FormSectionSubmit
            label={ t('common.save') }
            goToPrevPage={ goToPrevPage }
            loading={ isSubmitting }
          />
        ) : (
          <div style={ { display: 'flex', justifyContent: 'flex-end' } }>
            <Button
              onClick={ () => { setEnabledFields(true); } }
              variant='contained'
              color='secondary'
            >
              {t('Επιθυμώ να διορθώσω τα στοιχεία')}
            </Button>
          </div>
        )
      }

    </form>
  );
};

UserDetailsForm.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
};

UserDetailsForm.defaultProps = {
  onSubmit: () => { return null; },
  onError: () => { return null; },
};

export default UserDetailsForm;
