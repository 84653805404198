import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const langTypeToText = {
  'en-US': { name: 'English', icon: '/en_US_flag.png', short: 'EN', value: 3 },
  EN: { name: '', icon: '/en_US_flag.png' },

  'el-GR': { name: 'Greek', icon: 'el_GR_flag.png', short: 'ΕΛ', value: 2 },
  ΕΛ: { name: 'Greek', icon: 'el_GR_flag.png' },

  2: 'el-GR',
  3: 'en-US',

  US: 'America',
  GR: 'Greece',
  PL: 'Poland',
  CY: 'Cyprus',
  IT: 'Italy',
};

const LangSwitcher = ({ onChange = null }) => {
  const { i18n } = useTranslation();

  const locales = [
    { id: 2, name: 'el-GR' },
    { id: 3, name: 'en-US' },
  ];

  const [ lang, setLang ] = useState(i18n.language);

  useEffect(() => {
    setLang(i18n.language);
  }, [ i18n.language, setLang ]);

  return (
    <Select
      value={ lang }
      onChange={ (e) => {
        setLang(e.target.value);
        i18n.changeLanguage(e.target.value);

        if (null != onChange) {
          onChange(e.target.value);
        }
      } }
      disableUnderline
    >
      {locales.map((v) => {
        return (
          <MenuItem key={ v.id } value={ v.name }>
            <img
              style={ { marginRight: '10px' } }
              src={ langTypeToText[v.name]?.icon }
              alt={ langTypeToText[v.name]?.name }
            />
            {(langTypeToText[v.name].short)}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default LangSwitcher;
