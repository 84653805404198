// for testing purposes
import moment from 'moment';
import 'moment/locale/el';
import 'moment/locale/en-gb';
import { useTranslation } from 'react-i18next';

export const delayed = (cb, delay) => {
  return () => {
    return new Promise((resolve, reject) => {
      return setTimeout(() => {
        return Promise.resolve().then(() => {
          return cb();
        })
          .then(resolve)
          .catch(reject);
      }, delay);
    });
  };
};

export const dispatch = (cb) => {
  return delayed(cb, 0)();
};

export const linear = (from, to, inclusiveRight = true) => {
  return Array.from(Array(to - from + (inclusiveRight ? 1 : 0)).keys())
    .map((n) => { return n + from; });
};

export const isValidDate = (dateString) => {
  // First check for the pattern
  if (!/^\d\d\d\d-\d\d-\d\d$/.test(dateString)) { return false; }

  // Parse the date parts to integers
  const parts = dateString.split('-');
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (1000 > year || 3000 < year || 0 === month || 12 < month) { return false; }

  const monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

  // Adjust for leap years
  if (0 === year % 400 || (0 !== year % 100 && 0 === year % 4)) { monthLength[1] = 29; }

  // Check the range of the day
  return 0 < day && day <= monthLength[month - 1];
};

const resolveMomentLocaleFromi18nLocale = (i18nLocale) => {
  switch (i18nLocale) {
    case 'en-US':
      return 'en-gb';
    case 'el-GR':
    default:
      return 'el';
  }
};
export const useDatetimeFormat = () => {
  const { t, i18n } = useTranslation();
  const momentLocale = resolveMomentLocaleFromi18nLocale(i18n.language);

  return (dt) => {
    const mom = moment(dt);
    return mom.locale(momentLocale).calendar(null, {
      sameDay: `[${ t('today') }] HH:mm`,
      lastDay: `[${ t('yesterday') }] HH:mm`,
      sameElse: 'DD/MM/YYYY HH:mm',
    });
  };
};

export const parseDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDay = 10 > day ? `0${ day }` : day;
  const formattedMonth = 10 > month ? `0${ month }` : month;

  return `${ formattedDay }/${ formattedMonth }/${ year }`;
};
