import { FormControl, FormHelperText, MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useQuery } from 'react-query';
import useAPI from 'hooks/api-hooks';
import { useTranslation } from 'react-i18next';

const SpecialtySelectField = (
  {
    name,
    defaultValue,
    label,
    autoComplete,
    error,
    helperText,
    required,
    readOnly,
    loading,
    validate = null,
    disabled,
    isKitPGX = false,
    setExternalValue = undefined,
    ...rest
  },
) => {
  const [ value, setValue ] = useState('');
  const { client } = useAPI();
  const { t } = useTranslation();

  const { data: specialties } = useQuery(
    [ 'specialties', isKitPGX ], () => {
      if (isKitPGX) {
        return client.get('specialties', {
          params: {
            name_in: [ 'ΨΥΧΙΑΤΡΙΚΗ', 'ΝΕΥΡΟΛΟΓΙΑ', 'ΓΕΝΙΚΗ ΙΑΤΡΙΚΗ' ],
          },
        });
      }

      return client.get('specialties?');
    },
  );

  useEffect(() => {
    if ('' !== defaultValue && !defaultValue) {
      return;
    }
    setValue(defaultValue);

    if (setExternalValue) {
      setExternalValue(defaultValue);
    }
  }, [ defaultValue ]);

  return (
    <>
      <FormControl size='small' fullWidth error={ error } disabled={ disabled }>
        <FormLabel color='secondary' component='legend'>
          { `${ label }${ (required && !disabled) ? ' *' : '' }` }
        </FormLabel>
      </FormControl>
      <Grid container>
        <FormControl
          size='small'
          fullWidth
          error={ error }
          variant='outlined'
          disabled={ disabled }
        >
          <Select
            disabled={ !!readOnly || disabled }
            color='secondary'
            MenuProps={ {
              style: {
                maxHeight: 400,
              },
            } }
            margin='dense'
            value={ value }
            onChange={ (e) => {
              if (error && null !== validate) {
                validate(e.target.value);
              }

              if (setExternalValue !== undefined) {
                setExternalValue(e.target.value);
              }

              return setValue(e.target.value);
            } }
            name={ name }
            autoComplete={ autoComplete }
            endAdornment={ (
              <>
                {loading ? <CircularProgress color='inherit' size={ 20 } /> : null}
                {rest?.InputProps?.endAdornment ? rest.InputProps.endAdornment : null}
              </>
            ) }
            { ...rest }
          >
            {
              specialties?.map((spec) => {
                return (
                  <MenuItem value={ spec.name } key={ spec.id }>
                    {/* eslint-disable-next-line prefer-template */}
                    {t('common.specialties.' + spec.name)}
                  </MenuItem>
                );
              })
            }
          </Select>
        </FormControl>
      </Grid>
      { error && (
        <FormControl>
          <FormHelperText margin='dense' error>
            { t(helperText) }
          </FormHelperText>
        </FormControl>
      ) }

    </>
  );
};

SpecialtySelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  autoComplete: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  loading: PropTypes.bool,
};

SpecialtySelectField.defaultProps = {
  defaultValue: '',
  autoComplete: null,
  error: false,
  helperText: null,
  required: false,
  readOnly: false,
  loading: false,
};

export default SpecialtySelectField;
