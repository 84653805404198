import { Button, Grid, Typography, CircularProgress, DialogTitle, Dialog, DialogActions } from '@material-ui/core';
import { useAuth } from 'AuthCtx';
import { AppCtx } from 'AppCtx';
import Payment from 'components/forms/Payment';
import useAPI from 'hooks/api-hooks';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

const BuyRepeatNutrigenetix = () => {
  const [ _submitting, setSubmitting ] = useState(false);
  const [ alphaBankFormData, setAlphaBankFormData ] = useState(null);
  const [ paymentType, setPaymentType ] = useState('credit-card');
  const [ sureDialogOpen, setSureDialogOpen ] = useState(false);
  const [ cost, setCost ] = useState(50);
  const [ quantity, setQuantity ] = useState(50);
  const [ isPaying, setIsPaying ] = useState(false);

  const history = useHistory();

  const params = useLocation().search;
  const bankMsg = new URLSearchParams(params).get('msg');
  const { t } = useTranslation();

  const [ paymentDetails, setPaymentDetails ] = useState({
    first_name: '',
    last_name: '',
    billing_country: '',
    billing_address: '',
    billing_zip_code: '',
    billing_city: '',
    customer_email: '',
    customer_phone: '',
    installments: 0,
  });

  const { userIsAuthenticated } = useAuth();

  const { setJustBoughtAKit } = React.useContext(AppCtx);

  useEffect(() => {
    if ('success' === bankMsg) {
      setJustBoughtAKit(true);

      const bankParams = new URLSearchParams(params);

      bankParams.delete('msg');
      history.replace({
        search: bankParams.toString(),
      });
    }
  }, [ bankMsg ]);

  const { data: doctorData } = useQuery([ 'doctors', 'me', 'backend', `${ userIsAuthenticated }` ], () => {
    if (!userIsAuthenticated) {
      return undefined;
    }
    return client.get('doctors/me');
  });

  const { client } = useAPI();

  const { userAttributes, userAttributesLoaded } = useAuth();

  const [ afterPaymentDialogOpen, setAfterPaymentDialogOpen ] = useState(true);

  useEffect(() => {
    setPaymentDetails({
      first_name: userAttributes.firstName,
      last_name: userAttributes.lastName,
      billing_country: userAttributes.country,
      billing_address: userAttributes.streetAddress,
      billing_zip_code: userAttributes.postalCode,
      billing_city: userAttributes.city,
      customer_email: userAttributes.email,
      customer_phone: userAttributes.mobileNumber,
      installments: 0,
      kitId: null,
    });
  }, [ userAttributesLoaded ]);

  const updateAvailableKits = () => {
    setSubmitting(true);
  };

  const formRef = useRef(null);

  useEffect(async () => {
    if (alphaBankFormData) {
      setTimeout(
        () => {
          formRef.current.submit();
          setSubmitting(false);
        },
        3000,
      );
    }
  }, [ alphaBankFormData ]);

  const handlePGxKitSubmit = () => {
    setSubmitting(true);
    setSureDialogOpen(false);

    const requestBody = {
      first_name: paymentDetails.first_name,
      last_name: paymentDetails.last_name,
      billing_country: paymentDetails.billing_country,
      company: 'none',
      billing_address_1: paymentDetails.billing_address,
      billing_address_2: paymentDetails.billing_address,
      billing_zip_code: paymentDetails.billing_zip_code,
      billing_city: paymentDetails.billing_city,
      billing_state: paymentDetails.billing_city,
      customer_email: paymentDetails.customer_email,
      customer_phone: paymentDetails.customer_phone,
      installments: paymentDetails.installments,
      total_price: cost,
      quantity,
      kitId: null,
      doctor: doctorData?.id,
    };

    const url = process.env.REACT_APP_API_BANK;

    setIsPaying(true);

    client.post(url, requestBody).then((dd) => {
      setAlphaBankFormData({ formData: dd.form_data, postUrl: dd.post_url });
    });
  };

  return (
    <>
      {
        ('fail' === bankMsg || 'cancel' === bankMsg) && afterPaymentDialogOpen && (
          <Dialog
            open={ afterPaymentDialogOpen }
            onClose={ () => { setAfterPaymentDialogOpen(false); } }
          >
            <DialogTitle>
              {
                'fail' === bankMsg && (
                  <>
                    {t('reg_kit.review.non_doctor.pgx.payment_error')}
                  </>
                )
              }

              {
                'cancel' === bankMsg && (
                  <>
                    {t('reg_kit.review.non_doctor.pgx.cancel_payment')}
                  </>
                )
              }

            </DialogTitle>
            <DialogActions style={ { gap: '20px', padding: '16px 24px' } }>
              <Button
                style={ { color: 'white' } }
                onClick={ () => {
                  setAfterPaymentDialogOpen(false);

                  const bankParams = new URLSearchParams(params);

                  bankParams.delete('msg');
                  history.replace({
                    search: bankParams.toString(),
                  });
                } }
                variant='contained'
                color='secondary'
                autoFocus
              >
                {t('ok')}
              </Button>
            </DialogActions>
          </Dialog>
        )
      }

      <Payment
        sureDialogOpen={ sureDialogOpen }
        setSureDialogOpen={ setSureDialogOpen }
        paymentDetails={ paymentDetails }
        setPaymentDetails={ setPaymentDetails }
        handlePGxKitSubmit={ () => {
          return 'credit-card' === paymentType ? handlePGxKitSubmit() : updateAvailableKits();
        } }
        isKitPGX={ false }
        isKitOnlyNutri
        isKitCvd={ false }
        paymentType={ paymentType }
        setPaymentType={ setPaymentType }
        kitName='NutriGenetix Δυναμική Παρακολούθηση'
        cost={ cost }
        hideBankDeposit
        thematicPackage={ 0 }
      />

      {
        alphaBankFormData && (
          <form
            ref={ formRef }
            action={ alphaBankFormData.postUrl }
            method='POST'
            style={ { display: 'none', visibility: 'hidden', width: 0, height: 0, overflow: 'hidden' } }
          >
            {
              Object.keys(alphaBankFormData.formData)
                .map((k) => {
                  return (
                    <input
                      key={ k }
                      type='text'
                      name={ k }
                      value={ alphaBankFormData.formData[k] }
                    />
                  );
                })
            }
            <button type='submit'> Post to alpha bank </button>
          </form>
        )
      }

      <Grid
        container
        spacing={ 0 }
        style={ {
          minHeight: '573px',
          position: 'relative',
        } }
      >
        {
          isPaying && (
            <div style={ { height: '100%',
              width: '100%',
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center' } }
            >
              <CircularProgress color='secondary' />
            </div>
          )
        }
        <Grid item xs={ 12 }>
          <Typography style={ { textAlign: 'center', fontSize: '30px' } }>
            {t('Αγόρασε πακέτο Επανεκτίμησης NutriGenetix')}
          </Typography>
        </Grid>

        <Grid item xs={ 12 } style={ { display: 'flex', justifyContent: 'center' } }>
          <div>
            <div style={ { display: 'flex', justifyContent: 'center' } }>
              <img src='/repeat.svg' alt='repeat' style={ { rotate: '-45deg' } } />
            </div>

            <Typography
              style={ { width: '100%',
                textAlign: 'center',
                marginTop: '20px',
                marginBottom: '10px',
                fontSize: '35px',
                fontWeight: 'bold' } }
            >
              {t('Standard Pack')}
            </Typography>

            <div style={ { width: 'fit-content',
              alignItems: 'center' } }
            >
              <div style={ { display: 'flex', width: 'fit-content', maxWidth: '310px' } }>
                <div
                  style={ { border: '2px solid #B1B1B1',
                    borderRadius: '15px 0 0 15px',
                    position: 'relative',
                    background: '#F2F2F2',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    right: '-1px',
                    width: '150px' } }
                >
                  <Typography style={ {
                    fontSize: '35px',
                    height: 'fit-content',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  } }
                  >
                    10
                  </Typography>
                </div>
                <Typography
                  style={ { fontSize: '23px',
                    border: '2px solid #B1B1B1',
                    borderRadius: '0 15px 15px 0',
                    background: '#F2F2F2',
                    wordBreak: 'break-word',
                    padding: '10px' } }
                >
                  {t('Επανεκτιμήσεις NutriGenetix')}
                </Typography>
              </div>

              <Typography
                style={ { fontWeight: 'bold',
                  fontSize: '35px',
                  textAlign: 'center',
                  marginTop: '5px' } }
              >
                €50
              </Typography>

              <div style={ { display: 'flex', justifyContent: 'center' } }>
                <Button
                  variant='contained'
                  disabled={ isPaying }
                  onClick={ () => {
                    setCost(50);
                    setQuantity(10);
                    setSureDialogOpen(true);
                  } }
                  color='secondary'
                  style={ { marginTop: '20px',
                    width: '200px',
                    fontSize: '20px',
                    color: 'white' } }
                >
                  {t('Αγορά')}
                </Button>
              </div>
            </div>
          </div>
        </Grid>

        {/* <Grid item xs={ 12 } md={ 6 } style={ { display: 'flex', justifyContent: 'center' } }>
          <div>
            <div style={ { display: 'flex', justifyContent: 'center' } }>
              <img src='/repeat.svg' alt='repeat' style={ { rotate: '-35deg' } } />
            </div>

            <Typography
              style={ { width: '100%',
                textAlign: 'center',
                marginTop: '20px',
                marginBottom: '10px',
                fontSize: '35px',
                fontWeight: 'bold' } }
            >
              {t('Value Pack')}
            </Typography>

            <div style={ { width: 'fit-content',
              alignItems: 'center' } }
            >
              <div style={ { display: 'flex', width: 'fit-content', maxWidth: '310px' } }>
                <div
                  style={ { border: '2px solid #B1B1B1',
                    borderRadius: '15px 0 0 15px',
                    position: 'relative',
                    background: '#F2F2F2',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    right: '-1px',
                    width: '150px' } }
                >
                  <Typography style={ {
                    fontSize: '35px',
                    height: 'fit-content',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  } }
                  >
                    20
                  </Typography>
                </div>
                <Typography
                  style={ { fontSize: '23px',
                    border: '2px solid #B1B1B1',
                    borderRadius: '0 15px 15px 0',
                    background: '#F2F2F2',
                    wordBreak: 'break-word',
                    padding: '10px' } }
                >
                  {t('Επανεκτιμήσεις NutriGenetix')}
                </Typography>
              </div>

              <Typography
                style={ { fontWeight: 'bold',
                  fontSize: '35px',
                  textAlign: 'center',
                  marginTop: '5px' } }
              >
                €85
              </Typography>

              <div style={ { display: 'flex', justifyContent: 'center' } }>
                <Button
                  variant='contained'
                  disabled={ isPaying }
                  color='secondary'
                  onClick={ () => {
                    setCost(85);
                    setQuantity(20);
                    setSureDialogOpen(true);
                  } }
                  style={ { marginTop: '20px',
                    width: '200px',
                    fontSize: '20px',
                    color: 'white' } }
                >
                  {t('Αγορά')}
                </Button>
              </div>
            </div>
          </div>
        </Grid> */}
      </Grid>

    </>
  );
};

export default BuyRepeatNutrigenetix;
