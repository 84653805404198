/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import { FormLabel, FormControl, FormControlLabel, Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import useAPI from 'hooks/api-hooks';
import { useQuery, useQueryClient } from 'react-query';
import CountrySelectField, { countryNameToCode } from '../atoms/CountrySelectField';
import SimpleInputField from '../atoms/SimpleInputField';
import SimplePhoneField from '../atoms/SimplePhoneField';
import { useFormRules, useInputValidation } from '../../../hooks/form-rules';
import FormSection from '../atoms/FormSection';
import FormSectionSubmit from '../atoms/FormSectionSubmit';
import FormNames from '../atoms/form-names';
import { useAuth } from '../../../AuthCtx';
import { useDoctorSpecialties } from '../../../models/doctor-specialties';

const DoctorDetailsForm = (
  {
    onSubmit,
    onError,
  },
) => {
  const { t, i18n } = useTranslation();

  const { client } = useAPI();

  const { updateUserAttributes, userAttributes, userAttributesLoaded, userIsAuthenticated } = useAuth();

  const {
    firstName: initialFirstName,
    lastName: initialLastName,
    email: initialEmail,
    specialty: initialSpecialtyName,
    businessNumber: initialBusinessNumber,
    mobileNumber: initialMobileNumber,
    streetAddress: initialStreetAddress,
    postalCode: initialPostalCode,
    city: initialCity,
    country: initialCountry,
    brandName: initialBrandName,
  } = userAttributes || {};

  const [ isSubmitting, setSubmitting ] = useState(false);

  const { data: doctorData, isLoading: doctorDataLoading } = useQuery([ 'doctors', 'me', 'backend', `${ userIsAuthenticated }` ], () => {
    if (!userIsAuthenticated) {
      return undefined;
    }
    return client.get('doctors/me');
  });

  const initialAFM = doctorData?.tax_identification_number ?? '';

  const [ pshp, setPshp ] = useState(doctorData?.public_sector_hp ?? false);

  useEffect(() => {
    setPshp(doctorData?.public_sector_hp ?? false);
  }, [ doctorData ]);

  const {
    requiredFieldRule,
    phoneNumberRule,
    requiredPhoneRule,
    atMost10Rule,
    nineCharsRule,
  } = useFormRules();

  const [
    errorAFM,
    helperTextAFM,
    validateAFM,
  ] = useInputValidation(
    [
      nineCharsRule,
    ],
  );

  const [
    errorFirstName,
    helperTextFirstName,
    validateFirstName,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorLastName,
    helperTextLastName,
    validateLastName,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [ specialty, setSpecialty ] = useState(null);
  const [ specialtyError, specialtyHelperText, validateSpecialty ] = useInputValidation(
    [ requiredFieldRule ],
  );

  const {
    data: specialties,
    isLoading: specialtiesLoading,
  } = useDoctorSpecialties();

  useEffect(() => {
    if (!specialties) {
      return;
    }
    setSpecialty(specialties.find((s) => { return s.name === initialSpecialtyName; }));
  }, [ initialSpecialtyName, specialties, userAttributesLoaded ]);

  useEffect(() => {
    if (null == specialty || '' === specialty) {
      return;
    }

    const prevSpec = _.cloneDeep(specialty);
    setSpecialty('');
    setSpecialty(prevSpec);
  }, [ i18n.language, specialtiesLoading, userAttributesLoaded ]);

  const [
    errorStreetAddress,
    helperTextStreetAddress,
    validateStreetAddress,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorPostalCode,
    helperTextPostalCode,
    validatePostalCode,
  ] = useInputValidation(
    [
      requiredFieldRule,
      atMost10Rule,
    ],
  );

  const [
    errorCity,
    helperTextCity,
    validateCity,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorCountry,
    helperTextCountry,
    validateCountry,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorMobileNumber,
    helperTextMobileNumber,
    validateMobileNumber,
  ] = useInputValidation(
    [
      phoneNumberRule,
    ],
  );

  const [
    errorBusinessNumber,
    helperTextBusinessNumber,
    validateBusinessNumber,
  ] = useInputValidation(
    [
      requiredPhoneRule,
      phoneNumberRule,
    ],
  );

  const queryClient = useQueryClient();

  const handleSubmit = (e) => {
    setSubmitting(true);
    e.preventDefault();
    const formData = new FormData(e.target);
    let {
      [FormNames.firstName]: firstName,
      [FormNames.lastName]: lastName,
      // [FormNames.specialty]: specialty, this is not used since autocomplete component works a little bit different
      [FormNames.streetAddress]: streetAddress,
      [FormNames.postalCode]: postalCode,
      [FormNames.city]: city,
      [FormNames.country]: country,
      [FormNames.mobileNumber]: mobileNumber,
      [FormNames.countryMobileNumber]: countryMobileNumber,
      [FormNames.businessNumber]: businessNumber,
      [FormNames.countryBusinessNumber]: countryBusinessNumber,
      [FormNames.brandName]: brandName,
      afm,
    } = Object.fromEntries(formData);

    country = countryNameToCode(country);

    // greedy validation
    // don't validate all of them immediately

    if (![
      () => { return validateFirstName(firstName); },
      () => { return validateLastName(lastName); },
      () => { return validateSpecialty(specialty?.name); },
      () => {
        if ('ΔΙΑΙΤΟΛΟΓΙΑ - ΔΙΑΤΡΟΦΟΛΟΓΙΑ' === specialty.name) {
          return validateAFM(afm);
        }

        return validateAFM(afm, [ requiredFieldRule, nineCharsRule ]);
      },
      () => { return validateStreetAddress(streetAddress); },
      () => { return validatePostalCode(postalCode); },
      () => { return validateCity(city); },
      () => { return validateCountry(country); },
      () => {
        return validateMobileNumber(
          {
            phoneNumber: mobileNumber,
            country: countryMobileNumber,
          },
        );
      },
      () => {
        return validateBusinessNumber(
          {
            phoneNumber: businessNumber,
            country: countryBusinessNumber,
          },
        );
      },
    ].every((f) => {
      return true === f();
    })) {
      setSubmitting(false);
      return onError();
    }

    return updateUserAttributes({
      firstName,
      lastName,
      specialty: specialty.name,
      streetAddress,
      postalCode,
      country,
      city,
      mobileNumber,
      businessNumber,
      brandName,
    })
      .then(() => {
        return onSubmit({
          firstName,
          lastName,
          specialty,
          streetAddress,
          postalCode,
          city,
          mobileNumber,
          businessNumber,
        });
      })
      .then(() => {
        client.put('doctors/me/details',
          {
            name: firstName,
            family_name: lastName,
            phone_number: mobileNumber,
            specialty: specialty.name,
            business_number: businessNumber,
            address: JSON.stringify({ streetAddress, postalCode, city }),
            country,
            tax_identification_number: afm,
            public_sector_hp: pshp,
          }).then(() => {
          queryClient.invalidateQueries([ 'doctors', 'me', 'backend', 'true' ]);
        });
      })
      .catch(onError)
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (!userAttributesLoaded || doctorDataLoading) {
    return null;
  }

  return (
    <form
      noValidate
      onSubmit={ handleSubmit }
      style={ {
        padding: '24px',
      } }
    >
      <FormSection label={ t('Προσωπικά στοιχεία ιατρού') } color='#526D9C'>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <Grid container spacing={ 2 }>
              <Grid item xs={ 12 } md={ 6 }>
                <SimpleInputField
                  label={ t('firstName') }
                  lettersOnly
                  autoComplete='given-name'
                  defaultValue={ initialFirstName }
                  validate={ validateFirstName }
                  name={ FormNames.firstName }
                  required
                  error={ errorFirstName }
                  helperText={ helperTextFirstName }
                />
              </Grid>
              <Grid item xs={ 12 } md={ 6 }>
                <SimpleInputField
                  label={ t('lastName') }
                  defaultValue={ initialLastName }
                  lettersOnly
                  autoComplete='family-name'
                  validate={ validateLastName }
                  name={ FormNames.lastName }
                  required
                  error={ errorLastName }
                  helperText={ helperTextLastName }
                />
              </Grid>

              <Grid item xs={ 12 } md={ 6 }>
                <SimpleInputField
                  label={ t('brandName') }
                  defaultValue={ initialBrandName }
                  autoComplete='brand-name'
                  name={ FormNames.brandName }
                />
              </Grid>

              <Grid item xs={ 12 } md={ 6 }>
                <FormControl size='small' fullWidth>
                  <FormLabel color='secondary' component='legend' required>
                    {t('specialty')}
                  </FormLabel>
                </FormControl>
                <Autocomplete
                  getOptionSelected={ (option, v) => {
                    if (!v) { return null; }
                    return option.id === v.id;
                  } }
                  getOptionLabel={ (option) => { return t(option.name) || ''; } }
                  options={ specialties || [] }
                  loading={ specialtiesLoading }
                  value={ specialty }
                  onChange={ (e, v) => {
                    setSpecialty(v);
                  } }
                  renderInput={ (params) => {
                    return (
                      <TextField
                        { ...params }
                        placeholder={ `${ t('selectYourDomain') }...` }
                        variant='outlined'
                        color='secondary'
                        size='small'
                        error={ specialtyError && null == specialty }
                        helperText={ specialtyError && null == specialty ? t(specialtyHelperText) : null }
                        style={ { marginBottom: '8px' } }
                        InputProps={ {
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {specialtiesLoading ? (
                                <CircularProgress
                                  color='inherit'
                                  size={ 20 }
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        } }
                      />
                    );
                  } }
                />
              </Grid>

              <Grid item xs={ 12 } md={ 6 }>
                <SimpleInputField
                  label={ t('afm') }
                  defaultValue={ initialAFM }
                  required={ 'ΔΙΑΙΤΟΛΟΓΙΑ - ΔΙΑΤΡΟΦΟΛΟΓΙΑ' !== specialty?.name }
                  autoComplete='afm'
                  name='afm'
                  error={ errorAFM }
                  helperText={ helperTextAFM }
                />
              </Grid>

              <Grid
                item
                xs={ 12 }
                sm={ 6 }
                style={ { display: 'flex', alignItems: 'center', paddingTop: '25px' } }
              >
                <FormControlLabel
                  style={ { marginLeft: '0' } }
                  control={ (
                    <Checkbox
                      checked={ pshp }
                      onChange={ (e) => {
                        setPshp(e.target.checked);
                      } }
                    />
                  ) }
                  label={ t('Επαγγελματίας Υγείας Δημόσιου Φορέα') }
                  labelPlacement='start'
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection label={ t('physicalAddress') } color='#526D9C'>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <Grid container spacing={ 1 }>
              <Grid item xs={ 8 }>
                <SimpleInputField
                  label={ t('streetAddress') }
                  autoComplete='street-address'
                  defaultValue={ initialStreetAddress }
                  name={ FormNames.streetAddress }
                  required
                  error={ errorStreetAddress }
                  helperText={ helperTextStreetAddress }
                />
              </Grid>

              <Grid item xs={ 4 }>
                <SimpleInputField
                  label={ t('postalCode') }
                  defaultValue={ initialPostalCode }
                  autoComplete='postal-code'
                  name={ FormNames.postalCode }
                  required
                  error={ errorPostalCode }
                  helperText={ helperTextPostalCode }
                />
              </Grid>
              <Grid item container spacing={ 2 }>
                <Grid item xs={ 12 } sm={ 8 }>
                  <SimpleInputField
                    label={ t('cityOrTown') }
                    defaultValue={ initialCity }
                    name={ FormNames.city }
                    required
                    error={ errorCity }
                    helperText={ helperTextCity }
                  />
                </Grid>

                <Grid item xs={ 12 } sm={ 4 }>
                  <CountrySelectField
                    label={ t('country') }
                    defaultValue={ initialCountry }
                    name={ FormNames.country }
                    required
                    error={ errorCountry }
                    helperText={ helperTextCountry }
                    validate={ validateCountry }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection label={ t('contactDetails') } color='#526D9C'>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <SimpleInputField
              name={ FormNames.email }
              label={ t('email') }
              required
              defaultValue={ initialEmail }
              readOnly
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <SimplePhoneField
              name={ FormNames.businessNumber }
              defaultValue={ initialBusinessNumber }
              autoComplete='tel-national'
              required
              label={ t('businessNumber') }
              defaultCountryValue={ initialCountry ?? 'GR' }
              countrySelectName={ FormNames.countryBusinessNumber }
              error={ errorBusinessNumber }
              helperText={ helperTextBusinessNumber }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <SimplePhoneField
              name={ FormNames.mobileNumber }
              label={ t('mobileNumber') }
              defaultValue={ initialMobileNumber }
              autoComplete='tel-national'
              defaultCountryValue={ initialCountry ?? 'GR' }
              countrySelectName={ FormNames.countryMobileNumber }
              error={ errorMobileNumber }
              helperText={ helperTextMobileNumber }
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSectionSubmit
        color='#526D9C'
        label={ t('save') }
        loading={ isSubmitting }
      />

    </form>
  );
};

DoctorDetailsForm.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
};

DoctorDetailsForm.defaultProps = {
  onSubmit: () => { return null; },
  onError: () => { return null; },
};

export default DoctorDetailsForm;
