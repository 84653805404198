import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

function countryToFlag(isoCode) {
  return 'undefined' !== typeof String.fromCodePoint
    ? isoCode
      .toUpperCase()
      .replace(/./g, (char) => { return String.fromCodePoint(char.charCodeAt(0) + 127397); })
    : isoCode;
}

export const countries = [
  {
    value: 'US',
    name: 'USA',
    grName: 'Ηνωμένες Πολιτείες',
  },
  {
    name: 'Andorra',
    value: 'AD',
    grName: 'Ανδόρρα',
  },
  {
    name: 'Albania',
    value: 'AL',
    grName: 'Αλβανία',
  },
  {
    name: 'Austria',
    value: 'AT',
    grName: 'Αυστρία',
  },
  {
    name: 'Åland Islands',
    value: 'AX',
    grName: 'Νήσοι Ώλαντ',
  }, {
    name: 'Bosnia and Herzegovina',
    value: 'BA',
    grName: 'Βοσνία και Ερζεγοβίνη',
  }, {
    name: 'Belgium',
    value: 'BE',
    grName: 'Βέλγιο',
  }, {
    name: 'Bulgaria',
    value: 'BG',
    grName: 'Βουλγαρία',
  }, {
    name: 'Belarus',
    value: 'BY',
    grName: 'Λευκορωσία',
  }, {
    name: 'Switzerland',
    value: 'CH',
    grName: 'Ελβετία',
  }, {
    name: 'Cyprus',
    value: 'CY',
    grName: 'Κύπρο',
  }, {
    name: 'Czech Republic',
    value: 'CZ',
    grName: 'Τσεχία',
  }, {
    name: 'Germany',
    value: 'DE',
    grName: 'Γερμανία',
  }, {
    name: 'Denmark',
    value: 'DK',
    grName: 'Δανία',
  }, {
    name: 'Estonia',
    value: 'EE',
    grName: 'Εσθονία',
  }, {
    name: 'Spain',
    value: 'ES',
    grName: 'Ισπανία',
  }, {
    name: 'Finland',
    value: 'FI',
    grName: 'Φινλανδία',
  }, {
    name: 'Faroe Islands',
    value: 'FO',
    grName: 'Νήσοι Φερόες',
  }, {
    name: 'France',
    value: 'FR',
    grName: 'Γαλλία',
  }, {
    name: 'United Kingdom',
    value: 'GB',
    grName: 'Ηνωμένο Βασίλειο',
  }, {
    name: 'Guernsey',
    value: 'GG',
    grName: 'Γκέρνζι',
  }, {
    name: 'Greece',
    value: 'GR',
    grName: 'Ελλάδα',
  }, {
    name: 'Croatia',
    value: 'HR',
    grName: 'Κροατία',
  }, {
    name: 'Hungary',
    value: 'HU',
    grName: 'Ουγγαρία',
  }, {
    name: 'Ireland',
    value: 'IE',
    grName: 'Ιρλανδία',
  }, {
    name: 'Isle of Man',
    value: 'IM',
    grName: 'Νήσος του Μαν',
  }, {
    name: 'Iceland',
    value: 'IS',
    grName: 'Ισλανδία',
  }, {
    name: 'Italy',
    value: 'IT',
    grName: 'Ιταλία',
  }, {
    name: 'Jersey',
    value: 'JE',
    grName: 'Βαϊλάτο του Τζέρσεϊ',
  }, {
    name: 'Liechtenstein',
    value: 'LI',
    grName: 'Λίχτενσταϊν',
  }, {
    name: 'Lithuania',
    value: 'LT',
    grName: 'Λιθουανία',
  }, {
    name: 'Luxembourg',
    value: 'LU',
    grName: 'Λουξεμβούργο',
  }, {
    name: 'Latvia',
    value: 'LV',
    grName: 'Λετονία',
  }, {
    name: 'Monaco',
    value: 'MC',
    grName: 'Μονακό',
  }, {
    name: 'Moldova, Republic of',
    value: 'MD',
    grName: 'Μολδαβία',
  }, {
    name: 'Macedonia, The Former Yugoslav Republic of',
    value: 'MK',
    grName: 'Δημοκρατία της Βόρειας Μακεδονίας',
  }, {
    name: 'Malta',
    value: 'MT',
    grName: 'Μάλτα',
  }, {
    name: 'Netherlands',
    value: 'NL',
    grName: 'Ολλανδία',
  }, {
    name: 'Norway',
    value: 'NO',
    grName: 'Νορβηγία',
  }, {
    name: 'Poland',
    value: 'PL',
    grName: 'Πολωνία',
  }, {
    name: 'Portugal',
    value: 'PT',
    grName: 'Πορτογαλία',
  }, {
    name: 'Romania',
    value: 'RO',
    grName: 'Ρουμανία',
  }, {
    name: 'Russian Federation',
    value: 'RU',
    grName: 'Ρωσική Ομοσπονδία',
  }, {
    name: 'Sweden',
    value: 'SE',
    grName: 'Σουηδία',
  }, {
    name: 'Slovenia',
    value: 'SI',
    grName: 'Σλοβενία',
  }, {
    name: 'Svalbard and Jan Mayen',
    value: 'SJ',
    grName: 'Σβάλμπαρντ και Γιαν Μαγιέν',
  }, {
    name: 'Slovakia',
    value: 'SK',
    grName: 'Σλοβακία',
  }, {
    name: 'San Marino',
    value: 'SM',
    grName: 'Άγιος Μαρίνος',
  }, {
    name: 'Ukraine',
    value: 'UA',
    grName: 'Ουκρανία',
  }, {
    name: 'Holy See (Vatican City State)',
    value: 'VA',
    grName: 'Αγία Έδρα (Κράτος της Πόλης του Βατικανού)',
  }, {
    name: 'IDNA',
    value: 'IDNA',
    grName: 'IDNA',
  },
];

export const countryNameToCode = (name) => {
  return countries.find((co) => {
    return co.name === name || co.grName === name;
  })?.value;
};

const CountrySelectField = (
  {
    name,
    defaultValue,
    label,
    // autoComplete,
    error,
    helperText,
    required,
    readOnly,
    // loading,
    validate = null,
    onChange = null,
    // ...rest
  },
) => {
  const [ value, setValue ] = useState('');
  const { t, i18n } = useTranslation();

  const [ sortedCountries, setSortedCountries ] = useState([]);

  useEffect(() => {
    const copy = _.cloneDeep(countries);

    copy.sort((a, b) => {
      return t(a.name).localeCompare(t(b.name));
    });

    setSortedCountries(copy);
  }, [ i18n.language ]);

  const valueWithName = useMemo(() => {
    if ('' === value || null == value) {
      return null;
    }

    return countries.find((co) => {
      return co.value === value;
    });
  }, [ value ]);

  useEffect(() => {
    if (!defaultValue) {
      return;
    }
    setValue(defaultValue);
  }, [ defaultValue ]);

  const _filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => { return t(option.name); },
  });

  return (
    <>
      <FormControl size='small' fullWidth error={ error }>
        <FormLabel color='secondary' component='legend' disabled={ !!readOnly }>
          { `${ label }${ required ? ' *' : '' }` }
        </FormLabel>
      </FormControl>
      <Grid container>
        <Autocomplete
          id='country-select-demo'
          options={ sortedCountries }
          disableClearable
          fullWidth
          autoHighlight
          getOptionLabel={ (option) => { return option.name; } }
          disabled={ !!readOnly }
          color='secondary'
          value={ valueWithName }
          noOptionsText={ t('No options') }
          filterOptions={ (allOpt, state) => {
            return allOpt.filter((opt) => {
              return 'IDNA' !== opt.name
              && t(opt.name).toLowerCase().includes(state.inputValue.toLowerCase());
            });
          } }
          onChange={ (event, newValue) => {
            if (error && null !== validate) {
              validate(newValue.value);
            }

            if (null != onChange) {
              onChange(newValue?.value);
            }

            return setValue(newValue?.value);
          } }
          renderOption={ (option) => {
            return (
              <>
                <span style={ { marginRight: '5px' } }>{'IDNA' !== option.value ? countryToFlag(option.value) : ''}</span>
                {t(`common.countries.${ option.name }`)}
              </>
            );
          } }
          renderInput={ (params) => {
            return (
              <TextField
                { ...params }
                variant='outlined'
                margin='dense'
                color='secondary'
                name={ name }
                fullWidth
                style={ {
                  marginTop: '0px',
                } }
                inputProps={ {
                  ...params.inputProps,
                  value: t(params.inputProps.value),
                  autoComplete: 'new-password', // disable autocomplete and autofill
                } }
              />
            );
          } }
        />

      </Grid>
      { error && (
        <FormControl>
          <FormHelperText margin='dense' error>
            { t(helperText) }
          </FormHelperText>
        </FormControl>
      ) }

    </>
  );
};

CountrySelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  autoComplete: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  loading: PropTypes.bool,
};

CountrySelectField.defaultProps = {
  defaultValue: '',
  autoComplete: null,
  error: false,
  helperText: null,
  required: false,
  readOnly: false,
  loading: false,
};

export default CountrySelectField;
