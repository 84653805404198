import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import MobileStepper from '@material-ui/core/MobileStepper';
import { Grid, Icon, Button } from '@material-ui/core';
import useAPI from 'hooks/api-hooks';
import { useQuery } from 'react-query';
import { useNavigation, useOptionSerial } from 'NavigationProvider';
import useMediaQueries from '../hooks/media-query-hooks';

export const order = [
  'done',
  'analysis', 'analysis_process',
  'sample_received',
  'registration_done', 'sample_pending',
  'Not purchased',
];

const nameOrder = [
  'PGx',
  'IDNA Cardio Health',
  'Cardio Health',
  'NutriGenetix',
  '3-in-1',
  'Nutrition',
  'Vitamins',
  'Sports',
  'Biological Age',
];

export const colorPerType = {
  'Not purchased': 'white',

  Done: '#54C642',

  Registration_done: '#FEC153',
  Sample_pending: '#FEC153',

  Sample_received: '#D174F1',

  Analysis: '#1074BC',
};

export const progressPerType = {
  'Not purchased': 0,

  Done: 4,

  Registration_done: 1,
  Sample_pending: 1,

  Sample_received: 2,

  Analysis: 3,
};

export const changeType = {
  'Not purchased': 'Product not purchased',

  Done: 'Report available',

  Registration_done: 'Awaiting sample receipt',
  Sample_pending: 'Awaiting sample receipt',

  Sample_received: 'Sample received',

  Analysis: 'Sample analysis',
};

export const orderTypeStyle = {
  'Not purchased': {
    width: '16px',
    height: '16px',
    background: 'linear-gradient(141.34deg, #545454 14.93%, #EDFBD8 86.11%)',
    borderRadius: '100px',
  },
  Done: {
    width: '16px',
    height: '16px',
    background: 'linear-gradient(141.34deg, #54C642 14.93%, #EDFBD8 86.11%)',
    borderRadius: '100px',
  },
  Sample_received: {
    background: 'linear-gradient(141.34deg, #D174F1 14.93%, #EDFBD8 86.11%)',
    borderRadius: '100px',
    width: '16px',
    height: '16px',
  },
  Analysis: {
    background: 'linear-gradient(141.34deg, #1074BC 14.93%, #EDFBD8 86.11%)',
    borderRadius: '100px',
    width: '16px',
    height: '16px',
  },
  Registration_done: {
    background: 'linear-gradient(141.34deg, #FEC153 14.93%, #EDFBD8 86.11%)',
    borderRadius: '100px',
    width: '16px',
    height: '16px',
  },
  Sample_pending: {
    background: 'linear-gradient(141.34deg, #FEC153 14.93%, #EDFBD8 86.11%)',
    borderRadius: '100px',
    width: '16px',
    height: '16px',
  },
};

export const results = [
  {
    id: 1001,
    type: '3-in-1',
    alt_type: '3-in-1 kit',
    title: 'iDNA Test 3 σε 1',
    navTitle: 'iDNATest3to1',
    icon: '/3to1_logo.png',
    status: 'inactive',
    registrationStatus: 'Not purchased',
    fields: [
      {
        name: 'Διατροφή',
        url: 'diet',
      },
      {
        name: 'Βιταμίνες και Ιχνοστοιχεία',
        url: 'vitaminsHome',
      },
      {
        name: 'Άθληση',
        url: 'sports',
      },
    ],
    style: {
      background: 'linear-gradient(0deg, #F3F8E7, #F3F8E7), #FFFFFF',
      boxShadow: '0px 0px 9px rgba(187, 187, 187, 0.46)',
      borderRadius: '10px',
    },
    imageStyle: {
      padding: '21px 0 12px 0',
    },
  },

  {
    id: 1002,
    type: 'Nutrition',
    alt_type: 'Nutritional kit',
    title: 'iDNA Test Διατροφή',
    navTitle: 'nutritionalGuide',
    icon: '/nutri_logo_original.png',
    status: 'inactive',
    registrationStatus: 'Not purchased',
    fields: [],
    style: {
      background: 'linear-gradient(0deg, #F1F3F3, #F1F3F3), #FFFFFF',
      boxShadow: '0px 0px 9px rgba(245, 201, 30, 0.45)',
      borderRadius: '10px',
    },
    imageStyle: {
      padding: '21px 0 12px 0',
    },
  },

  {
    id: 1003,
    type: 'Biological Age',
    alt_type: 'Biological Age kit',
    title: 'iDNA Βιολογική Ηλικία',
    navTitle: 'biologicalAge',
    icon: '/idna-kit-biological-age-1.png',
    status: 'inactive',
    registrationStatus: 'Not purchased',
    fields: [],
    style: {
      background: 'rgba(84, 84, 84, 0.11)',
      border: '0.5px solid #545454',
      boxShadow: '0px 0px 9px rgba(84, 84, 84, 0.45)',
    },
  },

  {
    id: 1004,
    type: 'NutriGenetix',
    alt_type: 'NutriGenetix kit',
    title: 'iDNA NutriGenetix',
    navTitle: 'nutriGenetix',
    icon: '/nutrigenetix-labs.png',
    registrationStatus: 'Not purchased',
    doctorOnly: true,
    status: 'inactive',
    fields: [],
    style: {
      background: 'linear-gradient(0deg, #F1F3F3, #F1F3F3), #FFFFFF',
      boxShadow: '0px 0px 9px rgba(118, 62, 158, 0.45)',
      borderRadius: '10px',
      maxHeight: '100px',
    },
    imageStyle: {
      padding: '21px 0 12px 0',
    },
  },

  {
    id: 10045,
    type: 'NutriGenetix-Laboratories',
    alt_type: 'NutriGenetix Laboratories kit',
    title: 'iDNA NutriGenetix',
    navTitle: 'nutriGenetix',
    icon: '/iDNA Genomics - Nutrigenetix - Package_Kit - image.png',
    registrationStatus: 'Not purchased',
    status: 'inactive',
    fields: [],
    style: {
      background: 'linear-gradient(0deg, #F1F3F3, #F1F3F3), #FFFFFF',
      boxShadow: '0px 0px 9px rgba(118, 62, 158, 0.45)',
      borderRadius: '10px',
    },
  },

  {
    id: 1005,
    type: 'IDNA Cardio Health',
    alt_type: 'Cardio Health',
    other_alt_type: 'iDNA Cardio Health',
    title: 'IDNA Cardio Health',
    navTitle: 'iDNACardioHealth',
    icon: 'cvd-labs.png',
    status: 'inactive',
    registrationStatus: 'Not purchased',
    fields: [],
    style: {
      background: '#F1F3F3',
      boxShadow: '0px 0px 9px rgba(255, 7, 1, 0.45)',
      borderRadius: '10px',
    },
    imageStyle: {
      padding: '21px 0 12px 0',
    },
  },

  {
    id: 1006,
    type: 'PGx',
    alt_type: 'iDNA PGx kit',
    title: 'iDNA PGx CNS',
    navTitle: 'iDNAPGxCNS',
    registrationStatus: 'Not purchased',
    icon: '/pgx_logo_original.png',
    status: 'inactive',
    fields: [],
    style: {
      background: 'linear-gradient(0deg, #ECEFF4, #ECEFF4), #FFFFFF',
      boxShadow: '0px 0px 9px rgba(82, 106, 157, 0.45)',
      borderRadius: '10px',
    },
    imageStyle: {
      padding: '21px 0 12px 0',
    },
  },

  {
    id: 1007,
    type: 'Sports',
    alt_type: 'Sports kit',
    title: 'iDNA Άθληση',
    navTitle: 'sportsPage',
    registrationStatus: 'Not purchased',
    icon: '/iDNA Genomics - Sports - Package_Kit - image.png',
    status: 'inactive',
    fields: [],
    style: {
      background: '#F1F3F3',
      boxShadow: '0px 0px 9px rgba(49, 181, 225, 0.45)',
      borderRadius: '10px',
    },
  },

  {
    id: 1008,
    type: 'Vitamins',
    alt_type: 'Vitamins kit',
    title: 'iDNA Βιταμίνες & Ιχνοστοιχεία',
    navTitle: 'vitaminsPage',
    registrationStatus: 'Not purchased',
    icon: '/iDNA Genomics - Vitamins - Package_Kit - image.png',
    status: 'inactive',
    fields: [],
    style: {
      background: 'linear-gradient(0deg, #F1F3F3, #F1F3F3), #FFFFFF',
      boxShadow: '0px 0px 9px rgba(147, 194, 33, 0.45)',
      borderRadius: '10px',
    },
  },
];

export const useStyles = makeStyles({
  stepper: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: ({ color }) => { return `${ color }`; },
    },

    '& .MuiMobileStepper-progress': {
      width: '100%',
    },
  },
  item: {
    height: '100%',
    padding: '10%',
    boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
    borderRadius: '16px',
    position: 'relative',
  },
  hoverCursor: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  serialNumber: {
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    marginBottom: '32px',
  },
  title: {
    fontWeight: 600,
    fontSize: '20px',
    fontFamily: 'Roboto',
    lineHeight: '125.19%',
  },
  resultFieldVitamins: {
    '&:hover': {
      color: '#94c320',
      fontWeight: 500,
    },
  },

  resultFieldSports: {
    '&:hover': {
      color: '#31B5E1',
      fontWeight: 500,
    },
  },
  resultFieldDiet: {
    '&:hover': {
      color: '#F5C91E',
      fontWeight: 500,
    },
  },
});

const Result = ({ kit, status }) => {
  const { navigateToPage } = useNavigation();
  const { t } = useTranslation();
  const { isOnlySm } = useMediaQueries();

  const { setSerial: set3To1Serial } = useOptionSerial('3-in-1');
  const { setSerial: setCardioSerial } = useOptionSerial('IDNA Cardio Health');
  const { setSerial: setNutritionSerial } = useOptionSerial('nutrition');
  const { setSerial: setVitaminsSerial } = useOptionSerial('vitamins');
  const { setSerial: setSportsSerial } = useOptionSerial('sports');
  const { setSerial: setPgxSerial } = useOptionSerial('pgx');
  const { setSerial: setNutriGenetixSerial } = useOptionSerial('nutriGenetix');
  const { setSerial: setBioAgeSerial } = useOptionSerial('bioAge');

  const [ hovered, setHovered ] = useState(false);

  let newStatus = status;

  if (status === undefined) {
    newStatus = 'Not purchased';
  }

  newStatus = newStatus[0].toUpperCase() + newStatus.slice(1);

  const classes = useStyles({ color: colorPerType[newStatus] });

  return (
    <Grid
      item
      xs={ 12 }
      sm={ 6 }
      md={ 6 }
      lg={ 4 }
      style={ {
        height: '440px',
      } }
      onMouseOver={ () => { setHovered(true); } }
      onFocus={ () => { setHovered(true); } }
      onMouseLeave={ () => { setHovered(false); } }
    >

      <div
        className={ clsx(classes.item) }
        style={ { background: 'Not purchased' !== newStatus ? '#FFFFFF' : '#F1F3F3',
          padding: isOnlySm && '8%' } }
      >
        {'Not purchased' !== newStatus && (
          <div style={ {
            textAlign: 'right',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '125.19%',
            position: 'relative',
            top: '-5px',
          } }
          >
            Serial Number:
            {' '}
            {kit.serial}
          </div>
        )}
        <div style={ { display: 'flex', gap: '10px', justifyContent: 'space-between' } }>
          <div>
            <Icon
              style={ { width: '100px', height: '100px', ...kit.style } }
              className={ classes.icon }
            >
              <img
                src={ kit.icon }
                alt=''
                style={ {
                  height: '100px',
                  width: '100px',
                  ...kit.imageStyle,
                } }
              />
            </Icon>
          </div>
          <div style={ { marginTop: '19px',
            color: '#666666',
            width: 'fit-content',
            fontWeight: '400',
            fontSize: '15px',
            marginBottom: '8px',
            lineHeight: '125.19%' } }
          >
            {
              kit.fields.map((field) => {
                return (
                  <div
                    key={ field.name }
                  >
                    {t(field.name)}
                  </div>
                );
              })
            }
          </div>
        </div>
        <div className={ classes.title }>
          {t(kit.title)}
        </div>
        <div
          style={ {
            fontStyle: 'italic',
            fontFamily: 'Roboto',
            color: '#94c320',
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
          } }
        >
          <div
            style={ {
              ...orderTypeStyle[newStatus],
            } }
          />
          {t(changeType[newStatus])}
        </div>

        <MobileStepper
          variant='progress'
          steps={ 5 }
          position='static'
          activeStep={ progressPerType[newStatus] }
          style={ { width: 'min(80%, 250px)', color: 'red', marginTop: '10px' } }
          className={ classes.stepper }
          nextButton={ (
            <div style={ { display: 'none' } } />
          ) }
          backButton={ (
            <div style={ { display: 'none' } } />
          ) }
        />

        {
          'Done' === newStatus && (
            <Button
              variant='outlined'
              style={ {
                padding: '10px 20px',
                borderRadius: '15px',
                textTransform: 'none',
                borderColor: '#54C642',
                marginTop: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                minWidth: 'min(80%, 250px)',
                fontSize: '1.3em',
                textAlign: 'left',
              } }
              startIcon={ (
                <Icon color='secondary' style={ { height: '100%', width: '100%' } }>
                  <div style={ {
                    border: '0.5px solid #8FC440',
                    padding: '3px',
                    borderRadius: '10px',
                  } }
                  >
                    <img
                      src='/results-50x50px.svg'
                      alt=''
                      style={ { height: '40px', width: '40px' } }
                    />
                  </div>
                </Icon>
              ) }
              onClick={ () => {
                if ('3-in-1' === kit.type) {
                  set3To1Serial(`${ kit.serial }`);
                } else if ('IDNA Cardio Health' === kit.type || 'Cardio Health' === kit.type) {
                  setCardioSerial(`${ kit.serial }`);
                } else if ('Nutrition' === kit.type) {
                  setNutritionSerial(`${ kit.serial }`);
                } else if ('Sports' === kit.type) {
                  setSportsSerial(`${ kit.serial }`);
                } else if ('Vitamins' === kit.type) {
                  setVitaminsSerial(`${ kit.serial }`);
                } else if ('Biological Age' === kit.type) {
                  setBioAgeSerial(`${ kit.serial }`);
                } else if ('PGx' === kit.type) {
                  setPgxSerial(`${ kit.serial }`);
                } else if ('NutriGenetix' === kit.type || 'NutriGenetix-Laboratories' === kit.type) {
                  setNutriGenetixSerial(`${ kit.serial }`);
                }

                return navigateToPage({ pageId: kit.navTitle });
              } }
            >
              {t('viewThe')}
              <br />
              {t('resultsS')}
            </Button>
          )
        }

        {
          'Done' === newStatus && (
            <img
              src='/corner.svg'
              alt=''
              style={ {
                position: 'absolute',
                bottom: '11px',
                right: '11px',
                width: '25.5px',
                height: '23.5px',
                filter: hovered ? 'brightness(0) saturate(100%) invert(78%) sepia(10%) saturate(2971%) hue-rotate(30deg) brightness(90%) contrast(90%)' : 'none',
              } }
            />
          )
        }

      </div>

    </Grid>
  );
};

const Results = () => {
  const { client } = useAPI();
  const { isOnlySm } = useMediaQueries();

  const {
    data: kits,
  } = useQuery(
    [ 'registration-kits' ],
    () => {
      return client.get('registration-kits', {
        params: {
          _limit: -1,
          registrationStatus_ne: 'sample_pending',
        },
      });
    },
  );

  if (!kits) {
    return (<LoaderSkeleton />);
  }

  let allKits = kits.filter((kit) => { return null != kit.thematic_package && Object.prototype.hasOwnProperty.call(kit.thematic_package, 'id'); }).map((kit, id) => {
    const foundKit = results.find((result) => {
      return result.type === kit.thematic_package.thematic_category_id.name || result.alt_type === kit.thematic_package.thematic_category_id.name;
    });

    let type = kit.thematic_package.thematic_category_id.name;
    if ('Cardio Health' === type) {
      type = 'IDNA Cardio Health';
    }

    let status = kit.registrationStatus;
    if (true === kit.serial_number_id.sample_available && 'registration_done' === kit.registrationStatus) {
      status = 'sample_received';
    }

    return {
      id,
      type,
      serial: kit.serial_number_id.serial,
      navTitle: foundKit?.navTitle,
      icon: foundKit?.icon,
      title: foundKit?.title,
      style: foundKit?.style,
      fields: foundKit?.fields,
      status: 'active',
      registrationStatus: status,
      imageStyle: foundKit?.imageStyle,
    };
  });

  results.forEach((result) => {
    const found = allKits
      .find((kit) => { return kit.type === result.type; });

    if (!found && true !== result.doctorOnly) {
      allKits.push(result);
    }
  });

  allKits = allKits.sort((a, b) => {
    return nameOrder.indexOf(a.type) - nameOrder.indexOf(b.type);
  });

  allKits = allKits.sort((a, b) => {
    const f = order.indexOf(a.registrationStatus);
    const l = order.indexOf(b.registrationStatus);

    if ((1 === f || 2 === f)
        && (1 === l || 2 === l)
    ) {
      return 0;
    }

    if ((4 === f || 5 === f)
        && (4 === l || 5 === l)
    ) {
      return 0;
    }

    return f - l;
  });

  return (
    <Grid
      container
      spacing={ isOnlySm ? 1 : 5 }
    >
      {
        allKits.map((kit) => {
          return (
            <Result key={ kit.id } kit={ kit } status={ kit.registrationStatus } />
          );
        })
      }

    </Grid>
  );
};

export default Results;
