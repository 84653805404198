/* eslint-disable no-unused-vars */
import { useNavigation, useOptionSerial } from 'NavigationProvider';
import { LoaderSkeleton, NutriBioSuccessfullRegistrationPage } from 'components/layout/KitRegistrationDashboard';
import { useAlerts } from 'hooks/alerts-hooks';
import useAPI from 'hooks/api-hooks';
import { useKit } from 'models/kits';
import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import CollectionKeys from 'models/atoms/collection-keys';

export const RepeatQuestionnairePage = ({ kitId }) => {
  const queryClient = useQueryClient();
  const { navigateToPage } = useNavigation();
  const { alertSuccess } = useAlerts();
  const { t } = useTranslation();
  const { updateKitMetadata } = useKit(kitId);

  console.log('kitId', kitId);

  if (kitId === undefined) {
    return <LoaderSkeleton />;
  }

  return (
    <div style={ { width: '100%', height: '100%' } }>
      <NutriBioSuccessfullRegistrationPage
        kitId={ kitId }
        newQuestionnaire
        onSubmit={ () => {
          alertSuccess(t('Το ερωτηματολογίο συμπληρώθηκε επιτυχώς'));
          navigateToPage({ pageId: 'repeat-nutrigenetix', topLevel: true });

          const now = new Date();
          const month = `${ now.getMonth() + 1 }`.padStart(2, '0');
          const date = `${ now.getDate() }`.padStart(2, '0');
          const registrationDoneDate = `${ now.getFullYear() }-${ month }-${ date }`;

          updateKitMetadata({
            registrationStatus: 'analysis',
            metadata: { registrationDoneDate },
          }).then(() => {
            queryClient.invalidateQueries([ 'registration-kits', 0 ]);
            queryClient.invalidateQueries([ CollectionKeys.Kits, 0 ]);
            queryClient.invalidateQueries([ CollectionKeys.Kits, 1 ]);
            queryClient.invalidateQueries([ CollectionKeys.Kits ]);
            queryClient.invalidateQueries([ CollectionKeys.Kits, 'COUNT' ]);
            queryClient.invalidateQueries([ CollectionKeys.Kits, { kitId: `${ kitId }` }]);
            queryClient.invalidateQueries([ CollectionKeys.DoctorSortedKits ]);
            queryClient.invalidateQueries([ CollectionKeys.DoctorSortedKits, 'COUNT' ]);
            queryClient.invalidateQueries([ 'doctors', 'me', 'backend', 'true' ]);
          });
        } }
      />
    </div>
  );
};

const RepeatNewQuestionnaire = () => {
  const { serial: kitId } = useOptionSerial('3-in-1');
  const { navigateToPage } = useNavigation();
  const { alertSuccess } = useAlerts();
  const { t } = useTranslation();
  const { client } = useAPI();

  const { data, isLoading } = useKit(kitId);
  const [ newKitId, setNewKitId ] = useState(undefined);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isLoading) {
      const newSerial = `${ data?.serial_number_id.serial }_${ uuid() }`;

      client.post('serial-numbers', {
        ancestor: data?.serial_number_id.id,
        serial: newSerial,
      }).then((e) => {
        return client.post('registration-kits/correlate',
          { serialNumber: newSerial,
            isChildrenRegistration: false,
            locale: data.locale,
            repeat_customer: data?.customer_id.id,
            registrationType: 'iDNA NutriGenetix kit' });
      }).then((dd) => {
        queryClient.invalidateQueries([ 'registration-kits', 0 ]);
        queryClient.invalidateQueries([ CollectionKeys.Kits, 0 ]);
        queryClient.invalidateQueries([ CollectionKeys.Kits, 1 ]);
        queryClient.invalidateQueries([ CollectionKeys.Kits ]);
        queryClient.invalidateQueries([ CollectionKeys.Kits, 'COUNT' ]);
        queryClient.invalidateQueries([ 'doctors', 'me', 'backend', 'true' ]);
        setNewKitId(dd.id);
      });
    }
  }, [ kitId, isLoading ]);

  if (newKitId === undefined) {
    return <LoaderSkeleton />;
  }

  return (
    <RepeatQuestionnairePage kitId={ newKitId } />
  );
};

export default RepeatNewQuestionnaire;
